import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import validateForm from '../components/validateForm';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import { motion } from 'framer-motion';


const SERVICE_ID = "service_36fuzq4";
const TEMPLATE_ID = "template_qt3crip";
const PUBLIC_KEY = "h_QaY8EAPiEhTGvxM";


const Page = styled.section`
	padding: 160px 0;
	background-color:  ${(props) => props.theme.body};
    position: relative;
    //min-height: 100vh;
    overflow: hidden;
    display: center;
`;

const FormTitle = styled.h1`
	margin-bottom: 24px;
    font-size: ${(props) => props.theme.fontxxxl};
    font-family: "Caveat";
    font-weight: 200;
    color: ${(props) => props.theme.text};
	line-height: 1.1;
	
`;

const FormColumn = styled.div`
	/* margin-bottom: 15px; */
	padding: 50px;
	background: white;
	border: 20px;
	/* padding: ${({ small }) => (small ? '0 50px' : '0 15px')}; */
	flex: 1;
	max-width: 60%;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 20px;
	flex-direction: column;
	@media screen and (max-width: 768px) {
		max-width: 100% !important;
		flex-basis: 100%;
	}

	img {
		@media screen and (max-width: 768px) {
			display: none;
		}
	}
`;

const FormRow = styled.div`
	display: flex;
	justify-content: center;
	margin: 0 -15px -15px -15px;
	flex-wrap: wrap;
	align-items: center;
`;

const FormWrapper = styled.form`
	/* max-width: 540px; */
	padding-top: 0;
	width: 100%;
`;

const FormMessage = styled(motion.div)`
	color: ${({ error }) => (error ? 'red' : 'green')};
	padding: 5px;
	text-align: center;
	margin-top: 1rem;
`;

const FormInputRow = styled.div`
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: stretch;
	margin-bottom: 1.4rem;

	> p {
		font-size: 0.8rem;
		margin-top: 0.5rem;
		color: #f00e0e;
	}
`;
const FormInput = styled.input`
	display: block;
	padding-left: 10px;
	outline: none;
	border-radius: 2px;
	height: 40px;
	width: 100%;
	border: none;
	border-bottom: 1px solid #634E1C;
	font-size: 1rem;
`;

const FormTextArea = styled.textarea`
	display: block;
	padding-left: 10px;
	outline: none;
	border-radius: 2px;
	height: 200px;
	width: 100%;
	border: none;
	border-bottom: 1px solid #634E1C;
	font-size: 1rem;
	resize:none;
`;

const FormLabel = styled.label`
	display: inline-block;
	font-size: 0.9rem;
	margin-bottom: 0.3rem;
	color: #afafaf;
`;
const FormImgWrapper = styled.div`
	max-width: 555px;
	display: flex;
	justify-content: ${({ start }) => (start ? 'flex-start' : 'flex-end')};
`;
const FormImg = styled.img`
	padding-right: 0;
	border: 0;
	max-width: 100%;
	vertical-align: middle;
	display: inline-block;
	max-height: 500px;
`;

const FormButton = styled.button`
	border-radius: 4px;
	background: none;
	margin-top: 1.5rem;
	white-space: nowrap;
	color: ${(props) => props.theme.text};
	outline: none;
	width: 100%;
	font-size: 1.4rem;
	padding: 5px 15px;
	border: 2px solid #6C7C66;
	cursor: pointer;
	position: relative;
	overflow: hidden;

	&:hover {
		color: ${(props) => props.theme.title};;
		transition: background-color 0.4s ease-in;
		background-color: ${(props) => props.theme.text};;
	}
`;


const Form = () => {
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [phone, setPhone] = useState('');
	const [message, setMessage] = useState('');
	const [error, setError] = useState(null);
	const [success, setSuccess] = useState(null);
	let history = useNavigate();

	const handleSubmit = (e) => {
		e.preventDefault();
		const resultError = validateForm({ name, email, phone, message });

		if (resultError !== null) {
			setError(resultError);
			return;
		}

		emailjs
		.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, {
			publicKey: PUBLIC_KEY,
		})
		.then(
			() => {
			console.log('SUCCESS!');
			},
			(error) => {
			console.log('FAILED...', error.text);
			},
		);


		setName('');
		setEmail('');
		setPhone('');
		setMessage('');
		setError(null);
		setSuccess('Application was submitted!');
		
		setTimeout(() => {
			history.push('/21');
		});

		e.target.reset();
	};

	const messageVariants = {
		hidden: { y: 30, opacity: 0 },
		animate: { y: 0, opacity: 1, transition: { delay: 0.2, duration: 0.4 } },
	};

	const formData = [
		{ label: 'Name', value: name, name: "name", onChange: (e) => setName(e.target.value), type: 'text' },
		{ label: 'Email', value: email, name: "email", onChange: (e) => setEmail(e.target.value), type: 'email' },
		{
			label: 'Phone number',
			value: phone,
			name: "phone",
			onChange: (e) => setPhone(e.target.value),
			type: 'tel',
		},
		{
			label: 'Message',
			value: message,
			name: "message",
			onChange: (e) => setMessage(e.target.value),
			type: 'textarea',
		},
	];
	return (
		<Page id="form">
			
				<FormRow>
					<FormColumn small>
						<FormTitle>Contact us</FormTitle>
						<FormWrapper onSubmit={handleSubmit}>
							{formData.map((el, index) => (
								<FormInputRow key={index}>
									<FormLabel>{el.label}</FormLabel>

									{el.type === 'textarea' ?
									<FormTextArea
										type={el.type}
										placeholder={`Enter your ${el.label.toLocaleLowerCase()}`}
										value={el.value}
										name={el.name}
										onChange={el.onChange}
									/>
									:
									<FormInput
										type={el.type}
										placeholder={`Enter your ${el.label.toLocaleLowerCase()}`}
										value={el.value}
										name={el.name}
										onChange={el.onChange}
									/>
									}
								</FormInputRow>
							))}

							<FormButton type="submit">Envoyer</FormButton>
						</FormWrapper>
						{error && (
							<FormMessage
								variants={messageVariants}
								initial="hidden"
								animate="animate"
								error
							>
								{error}
							</FormMessage>
						)}
						{success && (
							<FormMessage
								variants={messageVariants}
								initial="hidden"
								animate="animate"
							>
								{success}
							</FormMessage>
						)}
					</FormColumn>
				</FormRow>
			
		</Page>
	);
};

export default Form;