export default function validateForm({ name, email, phone, message }) {
	if (!name.trim()) {
		return 'Username required';
	}
	const regex =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	if (!email) {
		return 'Email required';
	} else if (regex.test(email.toLocalLowerCase)) {
		return 'Email address is invalid';
	}
	const regexphone = /^[+]?(\d+\s?)+$/;
	if (!regexphone.test(phone)) {
		return 'Caratères invalides dans le numéro';
	}

	if (!message) {
		return 'entrez un message';
	} else if (message.length > 3000) {
		return 'Message trop long';
	}
	return null;
}