import React from "react";
import styled from "styled-components";
//import HomeVideo from"../assets/IMG_3573.MOV";  <video src={HomeVideo} type="video/mp4" autoPlay muted loop />
import HomeCover from"../assets/DSC06696.jpg";
import { motion } from "framer-motion";



const VideoContainer = styled.section`
  width: 100%;
  height: 100vh;
  position: relative;
  video {
    width: 100%;
    height: 100vh;
    object-fit: cover;

    @media (max-width: 48em) {
      object-position: center 10%;
    }
    @media (max-width: 30em) {
      object-position: center 50%;
    }
  }
  img{

    width: 100%;
    height: 100vh;
    object-fit: cover;

    @media (max-width: 48em) {
      object-position: center 10%;
    }
    @media (max-width: 30em) {
      object-position: center 50%;
    }
  }
`;
const CoucheSombre = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background-color: ${(props) => `rgba(${props.theme.bodyRgba},0.6)`};

`
const Title = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.title};
 
  h1{
    font-family: "Caveat";
    /* width:300px;
    height:110px;
    background-color:#6C7C66; */
    text-align:center;
    font-size: ${(props) => props.theme.fontxxxl};
    font-weight: 500;
    text-shadow: 1px 1px 1px ${(props) => props.theme.body};
   // margin: 0 auto;
/*     transition:
    width 1s,
    height 1s,
    background-color 1s,
    transform 1s; */
  }

  .squarekaki {
  width:300px;
  height:110px;
  padding: 0 0 0 0 !important;
  background: #6C7C66;
  /* transition:
    width 2s,
    height 2s,
    background-color 2s,
    transform 2s; */
}
`
const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      // delayChildren: 5, // 2
      // staggerChildren: 0.3,
      duration: 1.5,
      ease: 'easeInOut'
      
    },
  },
};

const videopage = () => {
  return (
    <VideoContainer data-scroll>
        <CoucheSombre/>
          <Title variants={container} initial="hidden" animate="show">
            <div data-scroll data-scroll-delay = '0.09' data-scroll-speed = "2" class="squarekaki">
                <h1 data-scroll data-scroll-delay = '0.13' data-scroll-speed = "0.5" >John.C</h1>
            </div>
          </Title>
          <motion.div animate={{scale: [ 1, 1.1, 1]}} transition={{ duration: 15, type: "tween", repeat:Infinity }}>
          <img width= "100%" src={HomeCover} />
          </motion.div>
    </VideoContainer>
  )
}

export default videopage
