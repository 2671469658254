import React, { Suspense } from 'react';
import styled from 'styled-components';

import VideoPage from '../components/videopage';
import NavBar from '../components/navbar';

const Page = styled.section`
position: relative;
min-height: 100vh;
overflow: hidden;
`;

const home = () => {
  return (
    <Page id="home">
      <Suspense fallback={<></>}>
      <VideoPage />
      <NavBar />
    </Suspense>
   </Page>
  );
};

export default home;
