import { createGlobalStyle } from "styled-components";
import '@fontsource/josefin-sans';
import '@fontsource/caveat';



const GlobalStyles = createGlobalStyle`
*,*::before,*::after{
    margin: 0;
    padding: 0;
}
/* @font-face {
    font-family: Josefin;
    src: url("../fonts/JOSEFINSANS-REGULAR.TTF");
}

@font-face {
    font-family: Caveat;
    src: url(${require("../fonts/CAVEAT-REGULAR.TTF")});
} */

body{
font-family: "Josefin Sans";
overflow-x : hidden;
}

h2,h3,h4,h5,h6{
    margin:0;
    padding:0;
    font-family: "Josefin Sans";
}

h1{
    margin:0;
    padding:0;
    font-family: "Caveat";
}

a{
    color: inherit;
    text-decoration: none;
}

`
export default GlobalStyles;