import { ThemeProvider } from "styled-components";
import GlobalStyles from "./styles/GlobalStyles";
import { themebeige } from "./styles/Themes";
import AboutPage from "./pages/About";
import HomePage from "./pages/home";
import ProductPage from "./pages/Collection";
import ContactPage from "./pages/Contact";
import FooterPage from "./pages/Footer";
import "locomotive-scroll/dist/locomotive-scroll.css";
import ScrollingGalery from "./components/ScrollingGalery";

import { useRef } from "react";
import { LocomotiveScrollProvider } from "react-locomotive-scroll";




function App() {
  const containerRef = useRef(null);
  return (
    <>
    <GlobalStyles/>
    <ThemeProvider theme={themebeige}>
      <LocomotiveScrollProvider
            options={{
              smooth: true,
              
              smartphone: {
                smooth: true,
              },
              tablet: {
                smooth: true,
              },
            }}
            watch={
              
              []
            }
            containerRef={containerRef}
          >
        
          <main className="App" data-scroll-container ref={containerRef}>
          <ScrollingGalery/>
          <HomePage key="home"/>
          <AboutPage key="About"/>
          <ProductPage key="Collection"/>
          <ContactPage key="form"/>
          <FooterPage key = "Footer"/>
          </main>
        
      </LocomotiveScrollProvider>
    </ThemeProvider>
    </>
  );
}

export default App;
