import React, { useState } from 'react'
import styled from 'styled-components';
import{motion} from 'framer-motion';
import { useLocomotiveScroll } from 'react-locomotive-scroll';

const NavContainer = styled(motion.div)`
width: 100vw;
z-index: 6;
position: absolute;
top: ${(props) => (props.click ? '0' : `-${props.theme.navHeight}`)}; // si on a clické alors on garde le bandeau ouvert sinon on le réduit
display: flex;
justify-content: center;
align-items: center;

transition: all 0.3s ease;
@media (max-width: 40em) {
    top: ${(props) => (props.click ? '0' : `calc(-50vh - 4rem)`)};

  }
`
const MenuElements = styled(motion.ul)`
position:relative;
height: ${(props) => props.theme.navHeight};
color: ${(props) => props.theme.text};
background-color: ${(props) => props.theme.body};
list-style: none;

display:flex;
justify-content: space-around;
align-items: center;

width:100%;
padding: 0 10rem;

@media (max-width: 40em) {
    flex-direction:column;
    padding:2rem 0;
    height: 50vh;
  }
`

const MenuClic = styled.li`
background-color: ${(props) => `rgba(${props.theme.grey},0.7)`}; //créer un voile sombre type overlay
list-style-type: style none;
color: ${(props) => props.theme.text};
width: 15rem; //width: 100%; pour toute la longeur
height: 3.1rem;

display: flex;
justify-content: center;
align-items:center;

font-size: ${(props) => props.theme.fontxxl};
font-weight: 500;
text-transform: uppercase;

cursor: pointer;

position: absolute;
top:100%;
left:50%;
transform: translateX(-50%);

//clip-path: polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%);
clip-path: inset(5% 10% 15% 10%);
//clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
@media (max-width: 40em) {
    width: 15rem;
    height: 3.1rem;

  }
`

const MenuElement = styled(motion.li)`
text-transform: uppercase;
color: ${(props) => props.theme.text};

@media (max-width: 40em) {
    flex-direction:column;
    padding:0.5rem 0;

  }
`
//pour utiliser UseState, le nom de la fonction doit être en majuscule
const Navbar = () => {
    const [click, setClick] = useState(false); //on set l'état initial à false

    const { scroll } = useLocomotiveScroll();

    const handleScroll = (id) => {
      let elem = document.querySelector(id);
      // console.log(elem);
      setClick(!click);
      scroll.scrollTo(elem, {
        offset: '-100',
        duration: '2000',
        easing: [0.25, 0.0, 0.35, 1.0],
      });
    };
  return (
    <NavContainer click={+click}
      initial = {{
        y: '-100%'
      }}
      animate = {{
        y:0
      }}
      transition = {{
        duration:1, delay:1
      }}
      >
        <MenuElements
        drag="y"
        dragConstraints={{ top: 0, bottom: 70 }}
        dragElastic={0.05}
        dragSnapToOrigin
        >
            <MenuClic onClick={() => setClick(!click)}>Menu</MenuClic> {/* sur un clic on change l'état vrai ou faux par son contraire */}
            <MenuElement
                whileHover={{ scale: 1.1, y: -5 }}
                whileTap={{ scale: 0.9, y: 0 }}
                onClick={() => handleScroll('#home')}
            >Home</MenuElement>
            <MenuElement
                whileHover={{ scale: 1.1, y: -5 }}
                whileTap={{ scale: 0.9, y: 0 }}
                onClick={() => handleScroll('#About')}
            >About Us</MenuElement>
            <MenuElement
                whileHover={{ scale: 1.1, y: -5 }}
                whileTap={{ scale: 0.9, y: 0 }}
                onClick={() => handleScroll('#Collection')}     
            >Collections</MenuElement>
            {/* <MenuElement
                whileHover={{ scale: 1.1, y: -5 }}
                whileTap={{ scale: 0.9, y: 0 }}
                onClick={() => handleScroll('#news')}      
            >News</MenuElement> */}
            <MenuElement
               whileHover={{ scale: 1.1, y: -5 }}
               whileTap={{ scale: 0.9, y: 0 }}
               onClick={() => handleScroll('#form')}       
            >Contact</MenuElement>
        </MenuElements>
    </NavContainer>
  )
}

export default Navbar
