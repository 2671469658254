import React from 'react';
import styled from 'styled-components';

import imgtisserand from "../assets/Images/IMG_E2138.webp";
import logo from "../assets/Images/Marque2.jpg";

const Page = styled.section`
position: relative;
min-height: 100vh;
width: 90vw;

background-color:  ${(props) => props.theme.body};
//overflow: hidden;
display: flex;
@media (max-width: 48em) {
    width: 90vw;
  }

  @media (max-width: 30em) {
    width: 100vw;
  }
//margin: 0 auto;
`;

const Title = styled.h1`
  font-size: ${(props) => props.theme.fontsemibig};
  font-family: "Josefin Sans";
  font-weight: 300;
  color: ${(props) => props.theme.titlebrun};
  position: absolute;
  top: 1rem;
  left: 5%;
  z-index: 5;

  @media (max-width: 64em) {
    font-size: ${(props) => `calc(${props.theme.fontBig} - 5vw)`};
    top: 0;
    left: 0%;
  }
  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontxxxl};
  }

  span {
    display: inline-block;
  }
`
const LeftSide = styled.div`
  width: 50%;
  font-size: ${(props) => props.theme.fontlg};
  font-weight: 300;
  color: ${(props) => props.theme.titlebrun};
  position: relative;
  z-index: 5;
  margin-top: 20%;
  left: 10%;
  top: 20%;
  text-align:justify;

  .p1{
    margin-top: 10%;
    margin-left: 7%;
    text-align:left;
  }
  .p2{
    margin-top: 5%;
    margin-left: 13%;
    text-align:left;
  }
  .p3{
    margin-top: 5%;
    margin-left: 20%;
    text-align:left;
  }

  @media (max-width: 64em) {
    width: 80%;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    margin: 0 auto;

    padding: 2rem;
    font-weight: 600;

    backdrop-filter: blur(2px);
    background-color: ${(props) => `rgba(${props.theme.textRgba},0.4)`};
    color: ${(props) => props.theme.title};
    border-radius: 20px;
  }
  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontmd};
  }
  @media (max-width: 30em) {
    font-size: ${(props) => props.theme.fontsm};
    padding: 2rem;
    width: 70%;
  }
`
const RightSide = styled.div`
  width: 50%;
  position: relative;
  //background-color:  ${(props) => props.theme.body};
  /* min-height: 100vh; */

  img {
    max-width : 60%;
    height: auto;
    bottom: 15%;
    position: absolute;
    left: 60%;
    display:inline-block; /* place next to each other */
  vertical-align: top;
    
  }
  .logo{
    max-width : 60%;
    height: auto;
    top: 7%;
    position: flex;
    left: 78%;
  }

  @media (max-width: 64em) {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
      height: 100vh;
      object-fit: cover;
      display:none;
    }
    .logo {
      width: 40%;
      height: auto;
      left: 5%;
      bottom: 5%;
      //align-self: center;
      display:block;
      //position:absolute;
    }
  }
`
const About = () => {
  return (
    <Page id="About">
        <Title data-scroll data-scroll-speed ="-2" data-scroll-direction="horizontal">
            The brand
        </Title>
        <LeftSide data-scroll data-scroll-sticky>
            John .C souhaite diffuser un luxe véritable et responsable, à contre courant de l’industrie.
            Plus que des vêtements, les créations de la marque John .C sont des œuvres uniques, 
            entièrement tissées par la main de l’homme dans le respect du savoir-faire historique et de l’environnement.

            <p data-scroll data-scroll-speed ="-1" data-scroll-direction="horizontal" className="p1" >Le tissage consiste en l’entrecroisement de fibres de chaîne et de trame qui constitueront le tissu final.</p>
            <p data-scroll data-scroll-speed ="-1.5" data-scroll-direction="horizontal" className="p2" >Ces techniques perpétuées par les canuts et les tapissiers sont inscrites au patrimoine culturel Français.</p>
            <p data-scroll data-scroll-speed ="-2" data-scroll-direction="horizontal" className="p3" >Aujourd’hui encore les pièces les plus rares et les plus précieuses sont faites au métier à tisser dit "à bras".</p>

        </LeftSide>
        <RightSide >
            <img src={logo} className="logo" alt="Tisserand travaillant sur son métier à tisser" data-scroll data-scroll-speed="5" data-scroll-direction="horizontal"/> 
            <img src={imgtisserand} alt="Tisserand travaillant sur son métier à tisser" data-scroll data-scroll-speed="5"/>

        </RightSide>
   </Page>
  );
};

export default About;
