//import React, { useLayoutEffect } from 'react'
//import { useInsertionEffect } from 'react';
import { useEffect } from 'react';
import { useLocomotiveScroll } from 'react-locomotive-scroll';
import ScrollTrigger from 'gsap/ScrollTrigger';
import gsap from 'gsap';

const ScrollingGalery = () => {
 
    const {scroll}= useLocomotiveScroll(); //une instance du locomotive scroll
    
    gsap.registerPlugin(ScrollTrigger); //on enregistre le scrolltrigger
 
    useEffect(() => {
        if(scroll){
            const element= scroll?.el;

            scroll.on('scroll', ScrollTrigger.update)

            ScrollTrigger.scrollerProxy(element, {
                scrollTop(value) {
                  return arguments.length
                    ? scroll.scrollTo(value, 0, 0)
                    : scroll.scroll.instance.scroll.y;//scroll vertical only in other case we have to define also a x axis scroll
                },
                getBoundingClientRect() {
                    return {
                      top: 0,
                      left: 0,
                      width: window.innerWidth,//on calque le scroller gsap sur la taille du scroll natif de la fenêtre?
                      height: window.innerHeight,
                    };
                  },
                  pinType: element.style.transform ? 'transform' : 'fixed',//for responsive mobile device
            });
              
        }
        return()=> {
            ScrollTrigger.addEventListener('refresh', () => scroll?.update());
            ScrollTrigger.refresh();
        }
    },[scroll])
     
    return null;//on retourne aucune vue c'est pour utiliser le scroller avec GSAP
}

export default ScrollingGalery
