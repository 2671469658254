export const themeclair = {
  body: '#fff',
  text: '#202020', // black shade
  bodyRgba: '255, 255, 255',
  textRgba: '32,32,32',

  grey: '#bebebe',

  fontxs: '0.75em',
  fontsm: '0.875em',
  fontmd: '1em', // 1em = 16px
  fontlg: '1.25em',
  fontxl: '2em',
  fontxxl: '3em',
  fontxxxl: '5em',
  fontBig: '10em',

  navHeight: '5rem',
};

export const themebeige = {
  body: '#EDE9DA',
  text: '#6C7C66', // kaki
  title: '#EDE9DA', // Ivoire
  bodyRgba: '237,233,218',
  textRgba: '32,32,32',
  titlebrun: '#634E1C', //brun
  //	rgb(108,124,102) kaki #6C7C66
  grey: '#bebebe',

  fontxs: '0.75em',
  fontsm: '0.875em',
  fontmd: '1em', // 1em = 16px
  fontlg: '1.25em',
  fontxl: '2em',
  fontxxl: '3em',
  fontxxxl: '5em',
  fontsemibig: '7em',
  fontBig: '10em',

  navHeight: '5rem',
};

export const darktheme = {
  body: '#202020',
  text: '#fff', // black shade
  bodyRgba: '32,32,32',
  textRgba: '255, 255, 255',

  grey: '#bebebe',

  fontxs: '0.75em',
  fontsm: '0.875em',
  fontmd: '1em', // 1em = 16px
  fontlg: '1.25em',
  fontxl: '2em',
  fontxxl: '3em',
  fontxxxl: '5em',
  fontBig: '10em',

  navHeight: '5rem',
};
