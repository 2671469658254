import { useRef } from 'react';
import { motion } from "framer-motion";
import styled from 'styled-components';
import ScrollTrigger from 'gsap/ScrollTrigger';
import gsap from 'gsap';
import { useLayoutEffect } from 'react';

import img1 from "../assets/Images/alpableu.webp";
import img2 from "../assets/Images/AlpagaBeiges.webp";
import img3 from "../assets/Images/alapgared.webp";
import img4 from "../assets/Images/aglace2.webp";
import img5 from "../assets/Images/MohairFauve.webp";
import img6 from "../assets/Images/MohairsoieBleu.JPG";
import img7 from "../assets/Images/Luxuriant.webp";
import img8 from "../assets/Images/HeraldicStarBleu.webp";
import img9 from "../assets/Images/HeraldicFire.webp";
import img10 from "../assets/Images/asinople.webp";
import img11 from "../assets/Images/adria2.jpg";



const Page = styled.section`
    min-height: 100vh;
    height: auto;
    width: 100vw;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color:  ${(props) => props.theme.body};

`
const Title = styled.h1`
  font-size: ${(props) => props.theme.fontxxxl};
  font-family: "Josefin Sans";
  font-weight: 300;
  color: ${(props) => props.theme.titlebrun};
  position: absolute;
  top: 1rem;
  left: 3%;
  z-index: 10;

  /* @media (max-width: 64em) {
    font-size: ${(props) => props.theme.fontxxl};
  }
  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontxl};
  } */

  span {
    display: inline-block;
  }
`
const TitleParallax = styled.h1.attrs({
  className: 'Titleparx',
  })`
  
  font-size: ${(props) => props.theme.fontxxxl};
  font-family: "Josefin Sans";
  font-weight: 300;
  color: ${(props) => props.theme.titlebrun};
  position: absolute;
  top: 14rem;
  left: ${props => props.ParallaxP.main};
  z-index: 1;
  opacity: 0.33;

  span {
    display: inline-block;
  };

  @media (max-width: 48em) {
    //width: 15rem;
    display:flex;
    font-size: ${(props) => props.theme.fontxl};
  }
`
const ParallaxP = {
  main: "2%"
};

const Boutontel = styled.button`
  background-color: ${(props) => props.theme.text};
  border: none;
  color: ${(props) => props.theme.body};
  //margin-left: 80px;

  cursor: pointer;
  font-size: ${(props) => props.theme.fontxl};
  font-family: "Josefin Sans";
  border-radius: 50px;
  text-align: center;
  

  &:hover {
  background-color: ${(props) => props.theme.body};
  color: ${(props) => props.theme.titlebrun};
  border: solid 1px #634E1C;
  }
`

const LeftSide = styled.div`
    min-height: 100vh;
    width: 35%;
    background-color:  ${(props) => props.theme.body};

    z-index:5;

    position:fixed;
    left:0;
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction: column;

    text-align:justify;

    h2{
    width:60%;
    margin: 0 auto;
    font-size: ${(props) => props.theme.fontxxl};
    font-family: "Josefin Sans";
    //font-weight: 300;
    color: ${(props) => props.theme.titlebrun};
    text-align: center;
    margin-bottom:10%;
    }

    p{
    width:60%;
    margin: 0 auto;
    font-size: ${(props) => props.theme.fontxl};
    font-family: "Josefin Sans";
    //font-weight: 300;
    color: ${(props) => props.theme.titlebrun};
    margin-bottom:10%;
    }

    q{
    width:60%;
    margin: 0 auto;
    font-size: ${(props) => props.theme.fontlg};
    font-family: "Josefin Sans";
    font-style: italic;
    color: ${(props) => props.theme.titlebrun}; 
    }

`

const RightSide = styled.div`
    left:15%;
    position: absolute;
    min-height: 100vh;
    //width:65%;
    padding-left:10%;
    display: flex;
    justify-content: flex-start;
    
    align-items: center;

    h2 {
      font-size: ${(props) => props.theme.fontxxxl};
      font-family: "Josefin Sans";
      font-weight: 300;
      color: ${(props) => props.theme.titlebrun};
      position: absolute;
      top: 15rem;
      left: 3%;
      z-index: 1; 
    }

`;
const Item = styled(motion.div)`
  z-index: 3;
  width: 25rem;
  height:auto;
  margin-right: 6rem;
  margin-top: 6rem;
  margin-left: 6rem;
  overflow:hidden;
  img {
    width: 25rem;
    height:auto;
    cursor: pointer;
    transition: transform 1s;
    z-index: 2;
  }

  img:hover { 
    
    transform: scale(1.1);
    overflow:hidden;
  }

  h1 {
    font-weight: 500;
    text-align: center;
    cursor: pointer;
    display: inline-block;
    width:25rem;
    font-size: ${(props) => props.theme.fontmd};
    font-family: "Josefin Sans";
    font-style: italic;
    color: ${(props) => props.theme.titlebrun};
    padding-top:15px;
    z-index: 6;
    margin: 0px auto;
    position: relative;
    background-color:  ${(props) => props.theme.body};
  }

  @media (max-width: 48em) {
    width: 20rem;
    //display:flex;
    h1{    font-size: ${(props) => props.theme.fontxs};
    text-align: left;}
    

    img {
    width: 20rem;
    height:auto;
    }
  }
`
const Items = ({ img, title = "" }) => {
    return (
      // x: 100, y: -100
      <Item
        initial={{ filter: "grayscale(100%)" }}
        whileInView={{ filter: "grayscale(0%)" }}
        transition={{ duration: 0.5 }}
        viewport={{ once: false, amount: "all" }}
        //whileHover={ { scale:1.1 }}
      >
        <img src={img} alt={title} />
        <h1> {title}</h1>
      </Item>
    );
};

const Chapter = styled.h1`
  z-index: 3;
  width: 25rem;
  height:auto;
  margin-right: 6rem;
  margin-top: 6rem;
  justify-content: center;
 
    overflow:hidden;
    font-weight: 500;
    text-align: center;
    display: inline-block;
    font-size: ${(props) => props.theme.fontxxl};
    font-family: "Josefin Sans";
    font-style: italic;
    color: ${(props) => props.theme.titlebrun};
    padding-top:15px;
    margin: 0px auto;
    position: relative;
    background-color:  ${(props) => props.theme.body};
    border: solid 1px;
  @media (max-width: 48em) {
    width: 15rem;
    display:flex;
    font-size: ${(props) => props.theme.fontlg};
  }
`
const Collection = () => {
gsap.registerPlugin(ScrollTrigger);  

    const ref = useRef(null);
    const Horizontalref = useRef(null);
    //const ParallaxRef = useRef("styled.TitleParallax");


    useLayoutEffect(() => {
      let element = ref.current;
  
      let scrollingElement = Horizontalref.current;
      //let titleselement = ParallaxRef.current;
      
      let pinWrapWidth = scrollingElement.offsetWidth;
      let t1 = gsap.timeline();

    
      setTimeout(() => {
        t1.to(element, {
          scrollTrigger: {
            trigger: element,
            start: "top top",
            end: `${pinWrapWidth} bottom`,
            scroller: ".App", //locomotive-scroll
            scrub: 1,
            pin: true,
            // markers: true,
            // anticipatePin: 1,
          },
          height: `${scrollingElement.scrollWidth}px`,//taille du scroller
          ease: "none",
        });
        


        t1.to(scrollingElement, {
          scrollTrigger: {
            trigger: scrollingElement,
            start: "top top",
            end: `${pinWrapWidth} bottom`,
            scroller: ".App", //locomotive-scroll
            scrub: 1,
            effects: true,
            // markers: true,
          },
          x: -pinWrapWidth,
  
          ease: "none",
          
        });
        //t1.to("hello",{x:100, duration:3});
        // t1.to(".Titleparx", {
        //   scrollTrigger: {
        //     trigger: ".Titleparx",
        //     start: "top top",
        //     end: `${pinWrapWidth} bottom`,
        //     scroller: ".App", //locomotive-scroll
        //     scrub: 1,
        //     effects: true,
        //     once: true,

        //   },

        //   x: 5000,
        //   //duration: 8,
        //   delay:0.5,
        //   toggleActions: "restart reverse reverse reverse",
        //   ease: "none",
          
          
        // });

      }, 1000);
      ScrollTrigger.refresh();

      return () => {
        t1.kill();
        ScrollTrigger.kill();
      };
    }, []);


    const onButtonClick = () => {
     
      // using Java Script method to get PDF file
      fetch("Catalogue JohnC.pdf").then((response) => {
          response.blob().then((blob) => {
           
              // Creating new object of PDF file
              const fileURL =
                  window.URL.createObjectURL(blob);
                   
              // Setting various property values
              let alink = document.createElement("a");
              alink.href = fileURL;
              alink.download = "Catalogue JohnC.pdf";
              alink.click();
          });
      });
    }

  return (
    <Page id="Collection" ref={ref}>
        <Title data-scroll data-scroll-speed="-1" data-scroll-direction="horizontal">
            Collections
        </Title>

        <RightSide data-scroll ref={Horizontalref} >
          <Chapter >
            Les Alpagas
          </Chapter>
          <TitleParallax ParallaxP={{main:"2%"}} className='Titleparx' >
            Les Alpagas
          </TitleParallax>
            <Items img={img3} title="“The Billionaire Red” Size : 180 x 29 cm / 70.9 x 11.4 in" />
            <Items img={img2} title="“The Billionaire Beige” Size : 170 x 28 cm / 66.9 x 11 in"/>
            <Items img={img1} title="“The Billionaire Blue” Size : 183 x 27 cm / 72 x 10.63 in"  />
          <Chapter>
            Les Mohairs 
          </Chapter>
          <TitleParallax ParallaxP={{main:"27%"}}  className="Titleparx">
            Les Mohairs
          </TitleParallax>
            <Items img={img4} title="“Tartan Glace” Size : 160 x 35 cm / 63 x 11 in" />
            <Items img={img5} title="“Tartan Fauve” Size : 175 x 29 cm / 68.9 x 11,4 in" />
            <Items img={img6} title="“Tartan Azure” Size : 174 x 28.5 cm / 68.5 x 11 in" />
            <Items img={img10} title="“Tartan Sinople” Size : 174 x 28.5 cm / 68.5 x 11 in" />
            <Items img={img11} title="“Adriana” Size : 160 x 35 cm / 63 x 13.78 in" />
          <Chapter>
            Les Merinos
          </Chapter>
          <TitleParallax ParallaxP={{main:"66%"}}  className="Titleparx">
            Les Merinos
          </TitleParallax >
            <Items img={img8} title="“Librum Aquae” Size : 165 x 35 cm / 64.9  x 13.8 in"  />
            <Items img={img9} title="“Liber Ignis” Size : 155 x 35 cm / 61 x 13.78 in" />
          <Chapter>
            Les éditions spéciales et personnalisations
          </Chapter>
            <Items img={img7} title="“The Luxuriant” Size : 183 x 31 cm / 72 x 12.2 in 
            Composition : 98% Mohair, 2% Silk" />
            <Boutontel onClick={onButtonClick} >Téléchargez le catalogue </Boutontel>
        </RightSide>
        
    </Page>
  )
}

export default Collection
